import * as React from 'react';
import cn from 'classnames';
import css from './FranchiseSolutionsForm.scss';
import { object, string } from 'yup';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import Input from 'styleguide/components/Formik/Input/Input';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';
import Button from 'styleguide/components/Button/Button';
import { submitForm } from 'api/contactUs';
import { Status } from 'libs/utils/api/types';
import { phoneRegex } from 'utils/schema';
import { RouteComponentProps, withRouter } from 'react-router';
import A from 'styleguide/components/Links/A';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import Combobox from 'styleguide/components/Formik/Combobox/Combobox';

interface FormProps extends RouteComponentProps {
  className?: string;
}

const franchiseFormSchema = object().shape({
  userInput: object().shape({
    name: string().required('Name is required'),
    email: string().email('Please enter a valid email.').required('Email is required'),
    phoneNumber: string()
      .required('Phone number is required')
      .matches(phoneRegex, 'Phone number is not valid'),
  }),
  franchiseRequest: object().shape({
    company: string().required('Company is required'),
    industry: string(),
    franchiseCount: string(),
    annualBudget: string(),
  }),
});

const FranchiseSolutionsForm = ({ className, history }: FormProps) => {
  const [recaptchaResponse, setRecaptchaResponse] = React.useState<string>(null);
  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    submitForm({ ...values, recaptchaResponse, formType: 'franchiseRequest' }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        history.push('/contact-us/success');
      }
    });
  };

  return (
    <>
      <A className={css.anchorOffset} href="#" color="none" underline="none" name="contact-us" />

      <Formik
        initialValues={{
          name: '',
          email: '',
          company: '',
          phone: '',
          industry: '',
          franchiseCount: '',
          annualBudget: '',
        }}
        onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
          onSubmit(values, setStatus, setSubmitting, setErrors);
        }}
        validationSchema={franchiseFormSchema}
      >
        {() => (
          <div className={cn(className, 'bg-additional-graySmoke')}>
            <Form className={css.form}>
              <div className={css.formHeader}>Get more info on franchise solutions!</div>
              <Field name="userInput.name" inPlaceError component={Input} label="Full Name" />
              <Field name="userInput.email" inPlaceError type="email" component={Input} label="Email" />
              <Field name="userInput.phoneNumber" inPlaceError component={Input} label="Phone Number" />
              <Field
                name="franchiseRequest.company"
                inPlaceError
                rows={5}
                component={Input}
                label="Company Name"
              />
              <TwoColumnFormField leftFieldClassName={css.leftField} rightFieldClassName={css.rightField}>
                <Field
                  name="franchiseRequest.franchiseCount"
                  component={Combobox}
                  label="Number of Locations"
                  options={[
                    {
                      key: '1',
                      name: '1 Location',
                    },
                    {
                      key: '2-5',
                      name: '2-5 Locations',
                    },
                    {
                      key: '6-10',
                      name: '6-10 Locations',
                    },
                    {
                      key: '11-25',
                      name: '11-25 Locations',
                    },
                    {
                      key: '25+',
                      name: 'Over 25 Locations',
                    },
                  ]}
                  inPlaceError
                />
                <Field
                  name="franchiseRequest.industry"
                  component={Combobox}
                  label="Industry"
                  options={[
                    {
                      key: 'Apparel',
                      name: 'Apparel',
                    },
                    {
                      key: 'Automotive',
                      name: 'Automotive',
                    },
                    {
                      key: 'Business Services',
                      name: 'Business Services',
                    },
                    {
                      key: 'Construction',
                      name: 'Construction',
                    },
                    {
                      key: 'Consumer Goods & Services',
                      name: 'Consumer Goods & Services',
                    },
                    {
                      key: 'Education',
                      name: 'Education',
                    },
                    {
                      key: 'Financial Services',
                      name: 'Financial Services',
                    },
                    {
                      key: 'Food & Beverage',
                      name: 'Food & Beverage',
                    },
                    {
                      key: 'Furniture',
                      name: 'Furniture',
                    },
                    {
                      key: 'Healthcare & Medical',
                      name: 'Healthcare & Medical',
                    },
                    {
                      key: 'Home & Garden',
                      name: 'Home & Garden',
                    },
                    {
                      key: 'Hospitality & Travel',
                      name: 'Hospitality & Travel',
                    },
                    {
                      key: 'Media & Entertainment',
                      name: 'Media & Entertainment',
                    },
                    {
                      key: 'Printing & Publishing',
                      name: 'Printing & Publishing',
                    },
                    {
                      key: 'Real Estate',
                      name: 'Real Estate',
                    },
                    {
                      key: 'Recreation',
                      name: 'Recreation',
                    },
                    {
                      key: 'Services',
                      name: 'Services',
                    },
                    {
                      key: 'Transportation & Logistics',
                      name: 'Transportation & Logistics',
                    },
                    {
                      key: 'Other',
                      name: 'Other',
                    },
                  ]}
                  inPlaceError
                />
              </TwoColumnFormField>
              <Field
                name="franchiseRequest.annualBudget"
                component={Combobox}
                label="Annual budget for print and promotional products?"
                options={[
                  {
                    key: 'Under $3000 per year',
                    name: 'Under $3,000 per year',
                  },
                  {
                    key: '$3,000 - $5,000 per year',
                    name: '$3,000 - $5,000 per year',
                  },
                  {
                    key: '$5,001 - $10,000 per year',
                    name: '$5,001 - $10,000 per year',
                  },
                  {
                    key: '$10,001 - $50,000 per year',
                    name: '$10,001 - $50,000 per year',
                  },
                  {
                    key: 'More than $50,000 per year',
                    name: 'More than $50,000 per year',
                  },
                ]}
                inPlaceError
                labelClassName="!text-[0.82rem]"
              />
              <Captcha verifyCallback={response => setRecaptchaResponse(response)} />
              <Button color="orange" type="submit">
                Submit Request
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default withRouter(FranchiseSolutionsForm);
