import * as React from 'react';
import cn from 'classnames';
import css from './FranchiseSolutionsHero.scss';
import Grid from 'styleguide/components/Grid/Grid';
import FranchiseSolutionsForm from 'bundles/App/pages/service/FranchiseSolutions/FranchiseSolutionsForm/FranchiseSolutionsForm';
import { H1, H2 } from 'styleguide/components/Heading';
import IconCheckmark from 'styleguide/icons/IconCheckmark';

import heroImg from 'assets/images/business-card-stack.png';

interface Props {
  a?: string;
}

interface TextWithIconProps {
  children: string;
  icon: React.ReactNode;
  containerClass?: string;
  iconWrapperClass?: string;
  contentWrapperClass?: string;
}

const TextWithIcon = ({
  children,
  icon,
  containerClass,
  iconWrapperClass,
  contentWrapperClass,
}: TextWithIconProps) => (
  <div className={cn(css.textWithIcon, containerClass)}>
    {!!icon && <div className={cn(css.iconWrapper, iconWrapperClass)}>{icon}</div>}
    <div className={cn(css.textWrapper, contentWrapperClass)}>{children}</div>
  </div>
);

const checkIcon = () => <IconCheckmark color="white" size="sm" />;

const FranchiseSolutionsHero = (props: Props) => (
  <Grid.Container className={cn(css.container)}>
    <Grid>
      <Grid.Row>
        <Grid.Col lg={6} className={css.heroText}>
          <H1 className={cn(css.heroText, css.heroLead)}>FRANCHISE SOLUTIONS</H1>
          <H2 className={cn(css.heroText, css.heroMain)}>Deliver a true brand experience.</H2>
          <H2 className={cn(css.heroText, css.heroTag)}>
            Empower franchises to market themselves with print and promo.
          </H2>
          <div className={cn(css.heroText, css.heroList)}>
            <TextWithIcon icon={checkIcon()}>Consistent brand colors on all print and promo.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Unified spend for better pricing.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Brand portals for single point-of-ordering.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Rush turnarounds and nationwide shipping.</TextWithIcon>
          </div>
        </Grid.Col>
        <Grid.Col className={cn(css.imageColumn)} style={{ backgroundImage: `url(${heroImg})` }} lg={6}>
          <div className={css.formContainer}>
            <FranchiseSolutionsForm className={css.formContainer} {...props} />
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default FranchiseSolutionsHero;
